import React from "react";
import { Container } from "react-bootstrap";
import { ROUTES } from "../constants/routes";
import { useEffect, useState } from "react";
import image001 from '../assets/image002.png';
import image003 from '../assets/image004.png';
import image005 from '../assets/image006.png';
import image007 from '../assets/image008.png';
import image009 from '../assets/image010.png';
import image011 from '../assets/image011.png';
import { useLocation } from "react-router-dom";

const AboutUs = () => {
    const [animate, setAnimate] = useState(false);
    const { hash } = useLocation();

    useEffect(() => {
        document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });
        setAnimate(true);

        highlightHashElement();
    }, []);

    const highlightHashElement = () => {
        document.querySelectorAll('.about-highlight').forEach((el) => {
            el.classList.remove('about-highlight');
        });

        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.classList.add('about-highlight');
            }
        }
    };

    return (
        <div className="top-banner-other-pages mx-0">
            <h2 className="position-relative text-center mt-2"><b>About Us</b></h2>
            {/* <Container fluid className="mx-3 my-5">
                <div className="d-flex">
                    <div className="banner-heading-title-dot mx-4 my-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                        <p className={`mt-5 banner-heading-text text-uppercase ${animate ? 'animated-heading' : ''}`}>
                            <strong>We</strong> always try to <strong>provide</strong> the <strong>best<br /> Business Solutions </strong>for <strong>Broker</strong>
                            <br /> clients & help <strong>to grow</strong> their <strong>Business sharply</strong> and <strong>smoothly</strong>
                        </p>
                    </div>
                    <img className="img-fluid d-none d-sm-block" src={BannerFAQ} alt="Poster" />
                </div>
            </Container> */}

            <Container className="container-get-in-touch my-3">
                <div className="d-padding pt-0">
                    <div className="d-flex">
                        <div className="banner-heading-title-dot my-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active banner-heading-title-text-active" aria-current="page">
                                        About Us
                                    </li>
                                </ol>
                            </nav>
                            {/* <p className={`mt-5 banner-heading-text text-uppercase ${animate ? 'animated-heading' : ''}`}>
                                <strong>We</strong> always try to <strong>provide</strong> the <strong>best<br /> Business Solutions </strong>for <strong>Broker</strong>
                                <br /> clients & help <strong>to grow</strong> their <strong>Business sharply</strong> and <strong>smoothly</strong>
                            </p> */}
                        </div>
                        {/* <img className="img-fluid d-none d-sm-block" src={BannerFAQ} alt="Poster" /> */}
                    </div>

                    <span className="other-pages-body-text"> Nice 1 was formed in 2007 to provide ancillary insurance products to FCA authorised general insurance brokers.<br /><br />
                        The aim has always been to provide innovative products that complement brokers client offerings which not only increase their profitability, but which also round off a professional service to protect client retention, all backed up with excellent customer service. This goal has never changed, nevertheless, the world we live in has.<br /><br />
                        We recognise that online aggregators coupled with the adverse effects from the covid pandemic, the rise in the cost of living and rise in interest rates, have pushed the public more towards online purchasing and the search “for a deal”.<br /><br />
                        To maintain our principal aim to assist brokers, we have risen to the challenge to make online purchasing easier for brokers and their clients by investing in the development of a new versatile online platform.<br /><br />
                        With the aid of feedback from our ever-growing list of supporters, we have worked tirelessly over recent years to produce software that furthers our principal aims:</span>

                    <div className="mt-4">
                        <h3 id="easy-for-you-to-quote" className="d-flex align-items-center"><br />
                            <img src={image001} className="me-3" /><b>Easy for you to quote & buy</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>All options visible and easily chosen.</li>
                            <li>Full product details available at time of quote.</li>
                            <li>Ability to amend commission to make a more attractive proposition in the face of competition.</li>
                            <li>Applications are clear and as simple as possible to complete.</li>
                            <li>Immediate access to purchased certificates and policy documents.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h3 id="easy-for-you-to-administer-policies" className="d-flex align-items-center"><br />
                            <img src={image003} className="me-2" /><b>Easy for you to administer policies</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>Easy access to existing policy documents.</li>
                            <li>Where policy conditions allow you may:</li>
                            o	change names.<br />
                            o	change addresses.<br />
                            o	cancel policies.<br />
                            o	renew policies.<br />
                            <li>All changes automatically update insurer and broker bordereaux.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h3 id="easy-for-you-to-report-from" className="d-flex align-items-center"><br />
                            <img src={image005} className="me-2" /><b>Easy for you to report from</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>You create your own reports on the portal whenever you require one, by date range, premium income, sales volumes, commission, branch etc.</li>
                            <li>Easy access to submitted invoices.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h3 id="bespoke-product-solutions" className="d-flex align-items-center"><br />
                            <img src={image007} className="me-2" /><b>Bespoke product solutions for you</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>Nice 1 has a successful background in sourcing and providing products to unique demands of brokers.</li>
                            <li>We have products that are being used successfully to compliment commercial insurances which assist client retention as more brokers make the move to commercial lines, and in improving broker margins.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h3 id="bespoke-software-solutions" className="d-flex align-items-center"><br />
                            <img src={image009} className="me-2" /><b>Bespoke software solutions for you</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>We can offer links from your website to our portal to facilitate online purchases.</li>
                            <li>We can provide White Label solutions to facilitate online purchases.</li>
                            <li>Especially for broker networks we can provide software solutions which appear that your branch staff are buying your products, and where you can boost your profitability further. </li>
                            <li>Our software is flexible in its applications, and we are always willing to discuss tailoring solutions to your requirements.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h3 id="appointed-representative-solutions" className="d-flex align-items-center"><br />
                            <img src={image011} className="me-2" /><b>Appointed representative solutions</b>
                        </h3>
                        <ul className="other-pages-body-text mx-5">
                            <li>Due to spiralling costs in constantly changing FCA compliance guidance, some brokers are now considering options of this nature, especially those offering online solutions.</li>
                            <li>Where non-industry businesses have a unique opportunity from an established customer/user base, we can tailor solutions to maximise business profitability and customer satisfaction.</li>
                            <li>If brokers can appoint an Appointed Representative of the kind mentioned above, our software may provide a flexible/simple solution.</li>
                        </ul>
                    </div>
                </div>
            </Container >
        </div>
    )
}

export default AboutUs;