import FooterComponent from './components/FooterComponent';
import HeaderComponent from './components/HeaderComponent';
import Home from './page/Home';
import AboutUs from './page/AboutUs';
import Testimonials from './page/Testimonials';
import Products from './page/Products';
import FAQ from './page/FAQ';
import Claims from './page/Claims';
import ContactUs from './page/ContactUs';

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from './constants/routes';

function App() {
  return (
    <div className="fluid-container">
      <BrowserRouter>
        <HeaderComponent />
        <Routes>
          <Route path={ROUTES.home} Component={Home} />
          <Route path={ROUTES.aboutUs} Component={AboutUs} />
          <Route path={ROUTES.testimonials} Component={Testimonials} />
          <Route path={ROUTES.products} Component={Products} />
          <Route path={ROUTES.claims} Component={Claims} />
          <Route path={ROUTES.faq} Component={FAQ} />
          <Route path={ROUTES.contactUs} Component={ContactUs} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;

window.addEventListener('scroll', function () {
  var header = document.getElementById('header');
  if (window.pageYOffset > 0) {
    header.classList.add('fixed-header');
  } else {
    header.classList.remove('fixed-header');
  }
});