import React from 'react';
import Popover from 'react-bootstrap/Popover';

const message = "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to."

export const popoverContents = [
    {
        id: 1,
        name: 'Andrew S.',
        title: 'CEO, Grey Insurance',
        location: 'London',
        content: (
            <Popover id="popover-custom-content-1" className="custom-popover">
                <span className="popoverClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </span>
                <Popover.Body>
                    <div className="custom-popover-content">
                        <p>{message}</p>
                    </div>
                </Popover.Body>
            </Popover>
        ),
    },
    {
        id: 2,
        name: 'Alexa Anderson',
        title: 'Founder, Insucom',
        location: 'Bristol',
        content: (
            <Popover id="popover-custom-content-2" className="custom-popover" >
                <span className="popoverClose" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </span>
                <Popover.Body>
                    <div className="custom-popover-content">
                        <p>{message}</p>
                    </div>
                </Popover.Body>
            </Popover>
        ),
    },
    {
        id: 3,
        name: 'Pari Malik',
        title: 'Sales Executive, Envato Groups',
        location: 'Nottingham',
        content: (
            <Popover id="popover-custom-content-3" className="custom-popover">
                <span className="popoverClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </span>
                <Popover.Body>
                    <div className="custom-popover-content">
                        <p>{message}</p>
                    </div>
                </Popover.Body>
            </Popover>
        ),
    },
    {
        id: 4,
        name: 'James N.',
        title: 'Accountant, Vankine Cons.',
        location: 'York Shire',
        content: (
            <Popover id="popover-custom-content-4" className="custom-popover">
                <span className="popoverClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </span>
                <Popover.Body>
                    <div className="custom-popover-content">
                        <p>{message}</p>
                    </div>
                </Popover.Body>
            </Popover>
        ),
    },
];

export const testimonialsHomePage = [
    {
        name: "Andrew S.",
        designation: "CEO, Grey Insurance",
        location: "London"
    },
    {
        name: "Alexa Anderson",
        designation: "Founder, Insucom",
        location: "Bristol"
    },
    {
        name: "Pari Malik",
        designation: "Sales Executive, Envato Groups",
        location: "Nottingham"
    },
    {
        name: "James N.",
        designation: "Accountant, Vankine Cons.",
        location: "York Shire"
    },
]

export const testimonialsPage = [
    {
        name: "Andrew Strauss",
        designation: "CEO, Grey Insurance",
        location: "London",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here. Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Eric Nicholas",
        designation: "Executive, Strauss Insurance",
        location: "Bermingham",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Brian Lara",
        designation: "Head of Sales, Alico Life",
        location: "Liverpool",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Alexa Anderson",
        designation: "Founder, Insucom",
        location: "Bristol",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here. Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Pari Malik",
        designation: "Sales Executive, Envato Groups",
        location: "Nottingham",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "James N.",
        designation: "Accountant, Vankine Consulting",
        location: "York Shire",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Leslie Alexander",
        designation: "CTO, Care Insurance",
        location: "Derby",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
    {
        name: "Devon Lane",
        designation: "President of Sales, Envy Inc.",
        location: "Leicester",
        content: "Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here."
    },
]