import { Button, Container } from "react-bootstrap";
import { ROUTES } from "../constants/routes";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Products = () => {
    const [active, setActive] = useState('commercial');
    const { hash } = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });


        if (hash) {
            if (hash.includes('personal')) {
                setActive('personal');
            } else {
                setActive('commercial');
            }
        }

        const timer = setTimeout(() => {
            highlightHashElement();
        }, 0);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timer);
        }
    }, []);


    const highlightHashElement = () => {
        document.querySelectorAll('.highlight').forEach((el) => {
            el.classList.remove('highlight');
        });

        if (hash) {
            const element = document.querySelector(hash);

            if (element) {
                element.classList.add('highlight');
            }
        }
    };

    return (
        <div className="top-banner-other-pages mx-0">
            <h2 className="text-center position-relative mt-2"><b>Nice 1 Products</b></h2>
            <Container className="container-get-in-touch my-3">
                <div className="d-padding pt-0">
                    <div className="d-flex">
                        <div className="banner-heading-title-dot my-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active banner-heading-title-text-active" aria-current="page">Nice 1 Products</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="text-center d-md-none">
                        <ul className="ul-list">
                            <li className={`li-list ${active === 'commercial' ? 'selected' : ''}`} onClick={() => setActive('commercial')}>
                                Commercial Lines
                            </li>
                            <li className={`li-list ${active === 'personal' ? 'selected' : ''}`} onClick={() => setActive('personal')}>
                                Personal Lines
                            </li>
                        </ul>
                    </div>

                    <div className="row gap-2 mt-5">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div className="col">
                                    <h3 className="fw-bolder mb-2 text-start">Commercial Lines</h3>
                                </div>
                            ) : (
                                <div className="col">
                                    <h3 className="fw-bolder mb-2 text-start">Personal Lines</h3>
                                </div>
                            )
                        ) : (
                            <>
                                <div className="col">
                                    <h3 className="fw-bolder mb-2 text-start">Commercial Lines</h3>
                                </div>
                                <div className="col">
                                    <h3 className="fw-bolder mb-2 text-start">Personal Lines</h3>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div id="commercial-gap-insurances" className="col highlight-container">
                                    <h3><br /><b>GAP Insurances:</b></h3>
                                    <span className="other-pages-body-text text">
                                        Maximum vehicle values, benefit levels and exclusions vary; however, qualifying car value up to £125,000. Combined Finance/RTI GAP and finance GAP products.
                                    </span>
                                    <ul className="other-pages-body-text">
                                        <li>Cars </li>
                                        <li>Motorbikes</li>
                                        <li>LCVs</li>
                                        <li>Taxis (different cover levels)</li>
                                    </ul>
                                </div>
                            ) : (
                                <div id="personal-gap-insurances" className="col highlight-container">
                                    <h3><br /><b>GAP Insurances:</b></h3>
                                    <span className="other-pages-body-text">Same as Commercial lines plus</span>
                                    <ul className="other-pages-body-text">
                                        <li>Motorhomes (different cover levels) </li>
                                    </ul>
                                </div>
                            )
                        ) : (
                            <>
                                <div id="commercial-gap-insurances" className="col highlight-container">
                                    <h3><br /><b>GAP Insurances:</b></h3>
                                    <span className="other-pages-body-text text">
                                        Maximum vehicle values, benefit levels and exclusions vary; however, qualifying car value up to £125,000. Combined Finance/RTI GAP and finance GAP products.
                                    </span>
                                    <ul className="other-pages-body-text">
                                        <li>Cars </li>
                                        <li>Motorbikes</li>
                                        <li>LCVs</li>
                                        <li>Taxis (different cover levels)</li>
                                    </ul>
                                </div>
                                <div id="personal-gap-insurances" className="col highlight-container">
                                    <h3><br /><b>GAP Insurances:</b></h3>
                                    <span className="other-pages-body-text">Same as Commercial lines plus</span>
                                    <ul className="other-pages-body-text">
                                        <li>Motorhomes (different cover levels) </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div id="commercial-excess-insurances" className="col highlight-container">
                                    <h3><br /><b>Excess Insurances:</b></h3>
                                    <span className="other-pages-body-text">
                                        Maximum benefits vary and there are numerous options of some products.
                                    </span>
                                    <ul className="other-pages-body-text">
                                        <li>Landlord</li>
                                        <li>Commercial Combined – for Limited Companies/Self-Employed & Partnerships</li>
                                        <li>Taxi</li>
                                        <li>Driving Instructor</li>
                                        <li>Business Car & LCV</li>
                                        <li>Motor Trade Plate</li>
                                        <li>Fleet – Car/LCV/ trucks up to 44t GVW</li>
                                        <li>Courier Fleet– Car/LCV/ trucks up to 44t GVW</li>
                                    </ul>
                                </div>
                            ) : (
                                <div id="personal-excess-insurances" className="col highlight-container">
                                    <h3><br /><b>Excess Insurances:</b></h3>
                                    <span className="other-pages-body-text">Maximum benefits vary and there are numerous options of some products.</span>
                                    <ul className="other-pages-body-text">
                                        <li>Personal Home</li>
                                        <li>Personal vehicle – car, motorbike, LCV</li>
                                        <li>Family Multi-vehicle</li>
                                        <li>Personal Motor & Home</li>
                                        <li>Personal Car & LCV Rental</li>
                                    </ul>
                                </div>
                            )
                        ) : (
                            <>
                                <div id="commercial-excess-insurances" className="col highlight-container">
                                    <h3><br /><b>Excess Insurances:</b></h3>
                                    <span className="other-pages-body-text">
                                        Maximum benefits vary and there are numerous options of some products.
                                    </span>
                                    <ul className="other-pages-body-text">
                                        <li>Landlord</li>
                                        <li>Commercial Combined – for Limited Companies/Self-Employed & Partnerships</li>
                                        <li>Taxi</li>
                                        <li>Driving Instructor</li>
                                        <li>Business Car & LCV</li>
                                        <li>Motor Trade Plate</li>
                                        <li>Fleet – Car/LCV/ trucks up to 44t GVW</li>
                                        <li>Courier Fleet– Car/LCV/ trucks up to 44t GVW</li>
                                    </ul>
                                </div>
                                <div id="personal-excess-insurances" className="col highlight-container">
                                    <h3><br /><b>Excess Insurances:</b></h3>
                                    <span className="other-pages-body-text">Maximum benefits vary and there are numerous options of some products.</span>
                                    <ul className="other-pages-body-text">
                                        <li>Personal Home</li>
                                        <li>Personal vehicle – car, motorbike, LCV</li>
                                        <li>Family Multi-vehicle</li>
                                        <li>Personal Motor & Home</li>
                                        <li>Personal Car & LCV Rental</li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div id="commercial-landlord-home-emergency-insurances" className="col highlight-container">
                                    <h3><br /><b>Landlord Home Emergency Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        £1000 of cover per claim, 3 claims per annum
                                    </span>
                                </div>
                            ) : (
                                <div id="personal-home-emergency-insurances" className="col highlight-container">
                                    <h3><br /><b>Home Emergency Insurance:</b></h3>
                                    <span className="other-pages-body-text">£1000 of cover per claim, 3 claims per annum</span>
                                </div>
                            )
                        ) : (
                            <>
                                <div id="commercial-landlord-home-emergency-insurances" className="col highlight-container">
                                    <h3><br /><b>Landlord Home Emergency Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        £1000 of cover per claim, 3 claims per annum
                                    </span>
                                </div>
                                <div id="personal-home-emergency-insurances" className="col highlight-container">
                                    <h3><br /><b>Home Emergency Insurance:</b></h3>
                                    <span className="other-pages-body-text">£1000 of cover per claim, 3 claims per annum</span>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div id="commercial-landlord-legal-rent-guarantee-insurances" className="col highlight-container">
                                    <h3><br /><b>Landlord Legal & Rent Guarantee Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        Provides legal fees when tenants lawfully evicted and pays outstanding rent.
                                    </span>
                                </div>
                            ) : (
                                <div id="personal-key-cover-insurances" className="col highlight-container">
                                    <h3><br /><b>Key Cover Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        Personal cover up to £1000 to replace lost or repair damaged keys, costs of gaining entry into car or home if keys locked inside.
                                    </span>
                                </div>
                            )
                        ) : (
                            <>
                                <div id="commercial-landlord-legal-rent-guarantee-insurances" className="col highlight-container">
                                    <h3><br /><b>Landlord Legal & Rent Guarantee Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        Provides legal fees when tenants lawfully evicted and pays outstanding rent.
                                    </span>
                                </div>
                                <div id="personal-key-cover-insurances" className="col highlight-container">
                                    <h3><br /><b>Key Cover Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        Personal cover up to £1000 to replace lost or repair damaged keys, costs of gaining entry into car or home if keys locked inside.
                                    </span>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ? (
                            active === 'commercial' ? (
                                <div id="breakdown-insurances" className="col highlight-container">
                                    <h3><br /><b>Breakdown Insurances for Taxis (Rates for taxis are different):</b></h3>
                                    <ul className="other-pages-body-text">
                                        <li>Roadside Assistance & Local Recovery</li>
                                        <li>Roadside Assistance & Local Recovery and Home Assist</li>
                                        <li>Roadside Assistance &a Local Recovery and Home Assist and  Nationwide Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery plus Home Assist</li>
                                    </ul>
                                </div>
                            ) : (
                                <div id="personal-breakdown-insurances" className="col highlight-container">
                                    <h3><br /><b>Personal Breakdown Insurances (and for Taxis – different rates):</b></h3>
                                    <ul className="other-pages-body-text">
                                        <li>Roadside Assistance & Local Recovery</li>
                                        <li>Roadside Assistance & Local Recovery and Home Assist</li>
                                        <li>Roadside Assistance &a Local Recovery and Home Assist and  Nationwide Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery plus Home Assist</li>
                                    </ul>
                                </div>
                            )
                        ) : (
                            <><div id="breakdown-insurances" className="col highlight-container">
                                <h3><br /><b>Breakdown Insurances for Taxis (Rates for taxis are different):</b></h3>
                                <ul className="other-pages-body-text">
                                    <li>Roadside Assistance & Local Recovery</li>
                                    <li>Roadside Assistance & Local Recovery and Home Assist</li>
                                    <li>Roadside Assistance &a Local Recovery and Home Assist and  Nationwide Recovery</li>
                                    <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery</li>
                                    <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery plus Home Assist</li>
                                </ul>
                            </div><div id="personal-breakdown-insurances" className="col highlight-container">
                                    <h3><br /><b>Personal Breakdown Insurances (and for Taxis – different rates):</b></h3>
                                    <ul className="other-pages-body-text">
                                        <li>Roadside Assistance & Local Recovery</li>
                                        <li>Roadside Assistance & Local Recovery and Home Assist</li>
                                        <li>Roadside Assistance &a Local Recovery and Home Assist and  Nationwide Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery</li>
                                        <li>European Roadside Assistance & Recovery and Nationwide Roadside Assistance & Recovery plus Home Assist</li>
                                    </ul>
                                </div></>
                        )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ?
                            (active === 'commercial' ? (
                                <div id="commercial-guaranteed-vehicle-insurance" className="col highlight-container">
                                    <h3><br /><b>Guaranteed Vehicle Hire Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        If a policyholder’s car is written-off or is
                                        undrivable following a road traffic accident of
                                        which the customer is at fault it will provide a
                                        car for the customer for a chosen period, or if
                                        a car can’t be found it will pay a cash
                                        allowance to the policyholder.
                                    </span>
                                </div>
                            ) : (
                                <div id="personal-guaranteed-vehicle-insurance" className="col highlight-container">
                                    <h3><br /><b>Guaranteed Vehicle Hire Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        If a policyholder’s car is written-off or is
                                        undrivable following a road traffic accident of
                                        which the customer is at fault it will provide a
                                        car for the customer for a chosen period, or if
                                        a car can’t be found it will pay a cash
                                        allowance to the policyholder.
                                    </span>
                                </div>
                            )
                            ) : (
                                <>
                                    <div id="commercial-guaranteed-vehicle-insurance" className="col highlight-container">
                                        <h3><br /><b>Guaranteed Vehicle Hire Insurance:</b></h3>
                                        <span className="other-pages-body-text">
                                            If a policyholder’s car is written-off or is
                                            undrivable following a road traffic accident of
                                            which the customer is at fault it will provide a
                                            car for the customer for a chosen period, or if
                                            a car can’t be found it will pay a cash
                                            allowance to the policyholder.
                                        </span>
                                    </div>
                                    <div id="personal-guaranteed-vehicle-insurance" className="col highlight-container">
                                        <h3><br /><b>Guaranteed Vehicle Hire Insurance:</b></h3>
                                        <span className="other-pages-body-text">
                                            If a policyholder’s car is written-off or is
                                            undrivable following a road traffic accident of
                                            which the customer is at fault it will provide a
                                            car for the customer for a chosen period, or if
                                            a car can’t be found it will pay a cash
                                            allowance to the policyholder.
                                        </span>
                                    </div>
                                </>
                            )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ?
                            (active === 'commercial' ? (
                                <div id="commercial-tools-in-transit" className="col highlight-container">
                                    <h3><br /><b>Tools in Transit – (standalone):</b></h3>
                                    <span className="other-pages-body-text">
                                        Covers portable tools, tool kits or test
                                        equipment whether owned by or hired even if
                                        loss or damage occurs during loading and
                                        unloading, additionally:
                                    </span>
                                    <ul className="other-pages-body-text">
                                        <li>if they are stolen from your vehicle during the day,</li>
                                        <li>for damage or loss as a result of a road traffic accident,</li>
                                        <li>if they are stolen while working/staying away from home at night.</li>
                                    </ul>
                                </div>
                            ) : (
                                <div id="personal-motor-personal-injury" className="col highlight-container">
                                    <h3><br /><b>Motor Personal Injury & Accidental Death Insurance:</b></h3>
                                    <span className="other-pages-body-text">
                                        Covers the policyholder and any other
                                        person entitled to drive the vehicle under the
                                        policyholder’s motor insurance policy and
                                        any passengers in the event of death or
                                        accidental bodily injury sustained during a
                                        motor accident.
                                    </span>
                                </div>
                            )
                            ) : (
                                <>
                                    <div id="commercial-tools-in-transit" className="col highlight-container">
                                        <h3><br /><b>Tools in Transit – (standalone):</b></h3>
                                        <span className="other-pages-body-text">
                                            Covers portable tools, tool kits or test
                                            equipment whether owned by or hired even if
                                            loss or damage occurs during loading and
                                            unloading, additionally:
                                        </span>
                                        <ul className="other-pages-body-text">
                                            <li>if they are stolen from your vehicle during the day,</li>
                                            <li>for damage or loss as a result of a road traffic accident,</li>
                                            <li>if they are stolen while working/staying away from home at night.</li>
                                        </ul>
                                    </div>
                                    <div id="personal-motor-personal-injury" className="col highlight-container">
                                        <h3><br /><b>Motor Personal Injury & Accidental Death Insurance:</b></h3>
                                        <span className="other-pages-body-text">
                                            Covers the policyholder and any other
                                            person entitled to drive the vehicle under the
                                            policyholder’s motor insurance policy and
                                            any passengers in the event of death or
                                            accidental bodily injury sustained during a
                                            motor accident.
                                        </span>
                                    </div>
                                </>
                            )}
                    </div>

                    <div className="row gap-2">
                        {isMobile ?
                            (active === 'commercial' ? (
                                <div id="commercial-goods-in-transit" className="col highlight-container">
                                    <h3><br /><b>Goods in Transit (for couriers):</b></h3>
                                    <span className="other-pages-body-text">
                                        <b>Commercial Goods Insurance</b> – loss or
                                        damage to cargo being transported and
                                        handheld scanners. Can be purchased with
                                        or without Employer’s Liability or Public
                                        Liability cover.
                                    </span>
                                </div>
                            ) : (
                                <div id="personal-scratch-dent-tyres-insurances" className="col highlight-container">
                                    <h3><br /><b>Scratch Dent, Tyres Alloy insurances:</b></h3>
                                    <span className="other-pages-body-text">
                                        Personal cosmetic repairs and replacement tyres.<br />Please contact Andy Acton on 07977413576.
                                    </span>
                                </div>
                            )
                            ) : (
                                <>
                                    <div id="commercial-goods-in-transit" className="col highlight-container">
                                        <h3><br /><b>Goods in Transit (for couriers):</b></h3>
                                        <span className="other-pages-body-text">
                                            <b>Commercial Goods Insurance</b> – loss or
                                            damage to cargo being transported and
                                            handheld scanners. Can be purchased with
                                            or without Employer’s Liability or Public
                                            Liability cover.
                                        </span>
                                    </div>
                                    <div id="personal-scratch-dent-tyres-insurances" className="col highlight-container">
                                        <h3><br /><b>Scratch Dent, Tyres Alloy insurances:</b></h3>
                                        <span className="other-pages-body-text">
                                            Personal cosmetic repairs and replacement tyres.<br />Please contact Andy Acton on 07977413576.
                                        </span>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </Container >
        </div >
    )
}

export default Products;