import React from "react";
import { Accordion, Container } from "react-bootstrap";
import { ROUTES } from "../constants/routes";
import { useEffect, useState } from "react";
import BannerFAQ from '../assets/FAQ_Banner.svg'

const FAQ = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });
        setAnimate(true);
    }, []);

    return (
        <div className="top-banner-other-pages mx-0 d-padding pt-0">
            <h2 className="text-center position-relative mt-2"><b>Frequently Asked Questions</b></h2>
            <Container className="container-get-in-touch my-3">
                <div className="d-flex">
                    <div className="banner-heading-title-dot my-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                </li>
                                <li className="breadcrumb-item active banner-heading-title-text-active" aria-current="page">FAQ's</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <Accordion defaultActiveKey="0" flush style={{ maxWidth: '1002px', margin: '0 auto' }}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How do I become a registered broker with Nice 1?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What types of ancillary insurance products do you offer for brokers?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What support or resources do you provide to registered brokers?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Can I customize insurance plans for my clients?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How do I access the 24/7 online shop for insurance products?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>What are the payment options available for brokers?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Is there a dedicated support team available for assistance?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>How often are your insurance products updated or enhanced?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>How often are your insurance products updated or enhanced?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Are there training resources available to help me understand your products better?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>How can I reach out for partnership or collaboration opportunities?</Accordion.Header>
                        <Accordion.Body>
                            <span className="other-pages-body-text">To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    )
}

export default FAQ;