import React, { useEffect, useRef, useState } from "react";
import Popover from 'react-bootstrap/Popover';

import posterImage from '../assets/Banner.webp';
import posterImageMobile from '../assets/Banner_mobile.webp';
import insImage from '../assets/Insurance_products.svg';
import avImage from '../assets/av.svg';
import image001 from '../assets/image002.png';
import image003 from '../assets/image004.png';
import image005 from '../assets/image006.png';
import image007 from '../assets/image008.png';
import image009 from '../assets/image010.png';
import image011 from '../assets/image011.png';
import { ROUTES } from "../constants/routes";
import { HashLink } from "react-router-hash-link";
import { Modal } from "react-bootstrap";
import { testimonialsHomePage } from "../constants/constant";

const Home = () => {
  const popoverRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1550);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClosePopover = () => {
    if (popoverRef.current) {
      popoverRef.current.click();
    }
  };

  const popoverContent = (
    <Popover id="popover-custom-content" className="custom-popover" ref={popoverRef} >
      <span className="popoverClose" onClick={handleClosePopover}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
        </svg>
      </span>
      <Popover.Body>
        <div className="custom-popover-content">
          <p>Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my
            offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to.</p>
        </div>
      </Popover.Body>
    </Popover>
  );

  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const handleModal = (data) => {
    setModalShow(true);
    setModalData(data);
  };

  return <>
    <div style={{ position: 'relative' }} className="fluid-container top-banner">

      <img loading="eager" className="desktop" src={posterImage} alt="Poster" style={{ width: '100%' }} />
      <img className="mobile d-none" src={posterImageMobile} alt="Poster" style={{ width: '100%' }} />

      <div className="banner-section mt-4 d-none d-sm-block">
        <h1 className="text-uppercase"><strong style={{ color: "#B20E0F" }}>Ancillary Product</strong><strong> Software <br />Solutions for </strong><strong style={{ color: "#B20E0F" }}>Brokers</strong><strong> In <br />Today's Online World</strong></h1>
        {/* <a className="def-btn" href="#">Open Nice1 Agency</a> */}
      </div>
    </div>

    <div className="m-3 d-sm-none">
      <h3 className="text-uppercase text-start lh-base"><strong style={{ color: "#B20E0F" }}>Ancillary Product</strong>
        <strong> Software Solutions for </strong>
        <strong style={{ color: "#B20E0F" }}>Brokers</strong>
        <strong> In Today's Online World</strong>
      </h3>
      <div className="text-center">
        <a className="def-btn" href="/">Open a Nice 1 Agency</a>
      </div>
    </div>

    <div className="Benefits d-padding">
      <div className="container">
        <h2 className="text-center position-relative">Why work with Nice 1?</h2>
        <div className="grid">
          <div className="grid-inner">
            <div className="image">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 50 48" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" />
              </svg> */}
              <img className="my-2" src={image001} />
            </div>
            <div className="content text-start ms-4 ">
              <HashLink to={`${ROUTES.aboutUs}/#easy-for-you-to-quote`} scroll={el => scrollWidthOffset(el)}><h4>EASY FOR YOU TO QUOTE & BUY</h4></HashLink>

              <ul>
                <li> All products easily identified.</li>
                <li> All product options visible.</li>
                <li> Quote process is simple.</li>
                <li> Product details available at quote stage.</li>
                <li> Applications are clear and simple.</li>
              </ul>
            </div>

          </div>

          <div className="grid-inner">
            <div className="image">
              <img className="my-2" src={image003} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 43 50" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" /></svg> */}
            </div>
            <div className="content text-start ms-4">
              <HashLink to={`${ROUTES.aboutUs}/#easy-for-you-to-administer-policies`} scroll={el => scrollWidthOffset(el)}><h4>EASY FOR YOU TO ADMINISTER POLICIES</h4></HashLink>
              <ul>
                <li>Easy access to policy documents.</li>
                <li>You make MTAs on the portal e.g.</li>
                - Name changes<br />
                - Address changes
              </ul>
            </div>
          </div>

          <div className="grid-inner">
            <div className="image">
              <img className="my-2" src={image005} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 38 50" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" />
              </svg> */}
            </div>
            <div className="content text-start ms-4">
              <HashLink to={`${ROUTES.aboutUs}/#easy-for-you-to-report-from`} scroll={el => scrollWidthOffset(el)}><h4>EASY FOR YOU TO REPORT FROM</h4></HashLink>
              <ul>
                <li>You create your own reports on the portal by product(s) e.g.</li>
                - by date range,<br />
                - premium income,<br />
                - sales volumes,<br />
                - commission.
              </ul>
            </div>

          </div>

          <div className="grid-inner">
            <div className="image">
              <img className="my-2" src={image007} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 50 50" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" /></svg> */}
            </div>
            <div className="content text-start ms-4">
              <HashLink to={`${ROUTES.aboutUs}/#bespoke-product-solutions`} scroll={el => scrollWidthOffset(el)}><h4>BESPOKE PRODUCT SOLUTIONS FOR YOU</h4></HashLink>
              <ul>
                <li>Providing products to the unique demands of brokers.</li>
                <li>Products used successfully to compliment commercial insurances and client retention.</li>
              </ul>
            </div>
          </div>

          <div className="grid-inner">
            <div className="image">
              <img className="my-2" src={image009} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 50 50" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" />
              </svg> */}
            </div>
            <div className="content text-start ms-4">
              <HashLink to={`${ROUTES.aboutUs}/#bespoke-software-solutions`} scroll={el => scrollWidthOffset(el)}><h4>BESPOKE SOFTWARE SOLUTIONS FOR YOU</h4></HashLink>
              <ul>
                <li>Links from your website to our portal for online purchases.</li>
                <li>White Label solutions for online purchases.</li>
                <li>Solutions which appear that your staff are buying your products.</li>
              </ul>
            </div>

          </div>

          <div className="grid-inner">
            <div className="image">
              <img className="my-2" src={image011} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 50 50" fill="none">
                <path d="M11.9134 21.8907C12.9822 21.8845 13.8273 22.3656 14.5583 23.097C15.6086 24.1475 16.6724 25.1869 17.7055 26.2545C18.0151 26.5748 18.1956 26.5834 18.5175 26.2594C21.8097 22.9473 25.1191 19.6498 28.4235 16.3487C29.0623 15.7106 29.7846 15.2553 30.7121 15.1387C32.8422 14.8712 34.6787 16.6039 34.6013 18.6705C34.5644 19.6744 34.1517 20.4855 33.4503 21.1838C30.9037 23.7192 28.3658 26.2619 25.8242 28.8009C24.1486 30.4748 22.4755 32.1499 20.7974 33.8214C19.1317 35.4817 17.106 35.5026 15.4329 33.852C13.4686 31.9131 11.5142 29.9631 9.57575 27.9996C8.10286 26.5086 7.98862 24.5708 9.27478 23.0651C9.95902 22.2649 10.8582 21.8833 11.9122 21.8907H11.9134ZM18.1121 32.8138C18.6047 32.8507 18.9339 32.5684 19.2545 32.2481C23.4349 28.0683 27.6189 23.8897 31.8005 19.7112C31.927 19.5848 32.0597 19.4571 32.1567 19.3099C32.4417 18.8804 32.4737 18.4153 32.228 17.9661C31.9884 17.528 31.5941 17.3415 31.088 17.3439C30.6114 17.3452 30.3079 17.6213 30.0033 17.9268C26.4114 21.5176 22.817 25.1071 19.2226 28.6954C18.3836 29.5336 17.8541 29.5372 17.0249 28.7101C15.6368 27.3271 14.2548 25.9367 12.8618 24.5586C12.2697 23.9732 11.5007 23.9536 10.986 24.4788C10.4885 24.9868 10.4995 25.7624 11.0548 26.3196C13.0817 28.3579 15.1196 30.3852 17.1527 32.4162C17.418 32.6813 17.7251 32.8482 18.1146 32.8151L18.1121 32.8138Z" fill="#34B233" />
              </svg> */}
            </div>
            <div className="content text-start responsive">
              <HashLink to={`${ROUTES.aboutUs}/#appointed-representative-solutions`} scroll={el => scrollWidthOffset(el)}><h4>APPOINTED REPRESENTATIVE SOLUTIONS</h4></HashLink>
              <ul>
                <li>Due to spiralling compliance costs some brokers are now considering AR status.</li>
                <li>Tailored solutions to maximise profitability and customer satisfaction where a company has a large customer base.</li>
              </ul>
            </div>

          </div>
        </div>

        <div className="button-box text-center mt-5">
          <a className="def-btn" href={ROUTES.home}>Open a Nice 1 Agency</a>

        </div>
      </div>
    </div>

    <div className="container mb-5 mt-0">

      <hr style={{ color: "#B20E0F", borderWidth: "1px", opacity: 1, borderRadius: "20px" }} />
    </div>

    <div className="Products mb-5">
      <div className="mb-5">
        <div>
          <h2 className="position-relative mb-0">Nice 1 Products</h2>
        </div>
        <div className="text-center">
          <h5 className="fw-bold">For Commercial and Personal Lines</h5>
        </div>
      </div>

      <div className="container">
        <div className="d-flex justify-content-center align-items-start">
          <img loading="eager" className="d-none d-lg-block" src={insImage} alt="Poster" />
          <div className="right-content ms-lg-4 ps-2 pe-4">
            <div className="d-flex flex-row">
              <div className="d-flex flex-column">
                <h5 className="fw-bolder mb-3 mx-3">Commercial Lines</h5>
                <ul className="dashed">
                  <li> <HashLink to={`${ROUTES.products}/#commercial-gap-insurances`} scroll={el => scrollWidthOffset(el)}>GAP Insurances</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-excess-insurances`} scroll={el => scrollWidthOffset(el)}>Excess Insurances</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-landlord-home-emergency-insurances`} scroll={el => scrollWidthOffset(el)}>Landlord Home Emergency Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-landlord-legal-rent-guarantee-insurances`} scroll={el => scrollWidthOffset(el)}>Landlord Legal and Rent Guarantee Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-breakdown-insurances`} scroll={el => scrollWidthOffset(el)}>Breakdown Insurances for Taxis <br />(and for Taxis – different rates)</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-guaranteed-vehicle-insurance`} scroll={el => scrollWidthOffset(el)}>Guaranteed Vehicle Hire Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-tools-in-transit`} scroll={el => scrollWidthOffset(el)}>Tools in Transit – (standalone)</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#commercial-goods-in-transit`} scroll={el => scrollWidthOffset(el)}>Goods in Transit (for couriers)</HashLink></li>
                </ul>
              </div>

              <div className="d-flex flex-column">
                <h5 className="fw-bolder mb-3 mx-3">Personal Lines</h5>
                <ul className="dashed">
                  <li> <HashLink to={`${ROUTES.products}/#personal-gap-insurances`} scroll={el => scrollWidthOffset(el)}>GAP Insurances</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-excess-insurances`} scroll={el => scrollWidthOffset(el)}>Excess Insurances</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-home-emergency-insurances`} scroll={el => scrollWidthOffset(el)}>Home Emergency Insurances</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-key-cover-insurances`} scroll={el => scrollWidthOffset(el)}>Key Cover Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-breakdown-insurances`} scroll={el => scrollWidthOffset(el)}>Personal Breakdown Insurances<br /> (and for Taxis – different rates)</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-guaranteed-vehicle-insurance`} scroll={el => scrollWidthOffset(el)}>Guaranteed Vehicle Hire Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-motor-personal-injury`} scroll={el => scrollWidthOffset(el)}>Motor Personal Injury Accidental<br /> Death Insurance</HashLink></li>
                  <li> <HashLink to={`${ROUTES.products}/#personal-scratch-dent-tyres-insurances`} scroll={el => scrollWidthOffset(el)}>Scratch Dent, Tyres Alloy insurances</HashLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <a className="def-btn" href={ROUTES.products}>View All Products</a>
        </div>
      </div>
    </div >

    {/* <div className="testimonials d-padding">
      <div className="container">
        <div className="d-flex justify-content-center flex-wrap">
          <div className="heading-sec w-25 pe-5">
            <h2 className="position-relative text-start mb-4">Testimonials</h2>
            <div className="button-box mt-4 d-none d-xl-block">
              <a className="def-btn" href={ROUTES.testimonials}>VIEW ALL</a>
            </div>
          </div>

          <div className="testimonials-inner w-75">
            <div className="grid-testimonials">
              {testimonialsHomePage && testimonialsHomePage?.map((data, index) => {
                return (
                  <div key={index} className="grid-inner-testimonials">
                    <div className="d-flex">
                      <img src={avImage} alt="Poster" />
                      <div className="content">
                        <h4 className="load-more" onClick={() => handleModal(data)}>{data.name}</h4>
                        <p>{data.designation}<span>{data.location}</span></p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="button-box mt-4 d-xl-none text-center">
            <a className="def-btn" href={ROUTES.testimonials}>VIEW ALL</a>
          </div>
        </div>
      </div>
    </div > */}

    {/* <div className="Benefits d-padding pt-0">
      <div className="container">
        <h2 className="text-center position-relative">FAQ</h2>

        <Accordion defaultActiveKey="0" flush style={{ maxWidth: '1002px', margin: '0 auto' }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How do I become a registered broker with Nice 1?</Accordion.Header>
            <Accordion.Body>
              To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>What types of ancillary insurance products do you offer for brokers?</Accordion.Header>
            <Accordion.Body>
              To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>What support or resources do you provide to registered brokers?</Accordion.Header>
            <Accordion.Body>
              To become a registered broker with us, simply fill out our online registration form on our website. Our team will review your application promptly.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="button-box text-center mt-5">
          <a className="def-btn" href={ROUTES.faq}>VIEW ALL</a>
        </div>
      </div>
    </div> */}

    <Modal show={modalShow} onHide={() => setModalShow(false)} centered keyboard backdrop size="lg">
      <Modal.Header closeButton>
        <div className="content d-flex flex-row">
          <img src={avImage} alt="Poster" />

          <div className="d-flex flex-column ps-2">
            <h4><b>{modalData?.name}</b></h4>
            <p>{modalData?.designation}<br /><span>{modalData?.location}</span></p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <p>Working with Nice 1 insurance has been a game-changer for my brokerage business. Their range of ancillary insurance products has expanded my
            offerings, delighting clients and boosting my bottom line, the point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to.</p>
        </div>
      </Modal.Body>
    </Modal>
  </>;
};

export default Home;
