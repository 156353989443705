import { Container, Modal } from "react-bootstrap";
import { ROUTES } from "../constants/routes";
import { useEffect, useState } from "react";
import avImage from '../assets/av.svg'
import doubleComma from '../assets/double-comma.svg'
import { testimonialsPage } from "../constants/constant";

const Testimonials = () => {
    const [animate, setAnimate] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });
        setAnimate(true);
    }, []);

    const handleModal = (data) => {
        setModalShow(true);
        setModalData(data);
    };

    return (
        <div className="top-banner-other-pages mx-0">
            <h2 className="text-center position-relative mt-2"><b>Testimonials</b></h2>
            <Container className="container-get-in-touch my-3">
                <div className="d-padding pt-0">
                    <div className="d-flex">
                        <div className="banner-heading-title-dot my-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active banner-heading-title-text-active" aria-current="page">Testimonials</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="grid-testimonial-page">
                        {testimonialsPage && testimonialsPage?.map((data, index) => {
                            console.log(data.content.length)
                            return (
                                <div key={index} className="grid-inner-testimonial-page d-flex">
                                    <div className="content text-start ms-4 ">
                                        {data.content.length > 300 ?
                                            <p>{data.content.slice(0, 300)} ......<span className="load-more" onClick={() => handleModal(data)}>load more</span></p> : data.content}
                                        <div className="d-flex mt-3 justify-content-between">
                                            <img src={avImage} alt="Poster" />
                                            <div className="mx-3">
                                                <h4 className="testimonials-page-client-name"><b>{data.name}</b></h4>
                                                <p>{data.designation}<br />{data.location}</p>
                                            </div>
                                            <img src={doubleComma} alt="Poster" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div className="d-flex justify-content-center mt-5">
                        <Button className="login-button rounded-pill btn btn-sm btn-xs">
                            Load More
                        </Button>
                    </div> */}
                </div>
            </Container >

            <Modal show={modalShow} onHide={() => setModalShow(false)} centered keyboard backdrop size="lg">
                <Modal.Header closeButton>
                    <div className="content d-flex flex-row">
                        <img src={avImage} alt="Poster" />

                        <div className="d-flex flex-column ps-2">
                            <h4><b>{modalData?.name}</b></h4>
                            <p>{modalData?.designation}<br /><span>{modalData?.location}</span></p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p>{modalData?.content}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default Testimonials;