import { Container } from "react-bootstrap";
import { ROUTES } from "../constants/routes";
import { useEffect, useState } from "react";

const Claims = () => {

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.body.scrollTop = window.scrollTo({ top: 0, behavior: 'instant' });
        setAnimate(true);
    }, []);

    return (
        <div className="top-banner-other-pages mx-0">
            <h2 className="text-center position-relative mt-2"><b>Claims Process</b></h2>
            <Container className="container-get-in-touch my-3">
                <div className="d-padding pt-0">
                    <div className="d-flex">
                        <div className="banner-heading-title-dot my-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={ROUTES.home} className="banner-heading-title-text position-relative">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active banner-heading-title-text-active" aria-current="page">Claims Process</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <span className="other-pages-body-text lh-lg">
                        <br />Nice 1 is not involved in the handling of claims and has no responsibility/influence over them;
                        therefore, to make a claim, policyholders should refer to their policy wording which has a detailed
                        claims process, and which provides the correct numbers and email addresses to use when making a
                        claim on their policy.<b> Failing to follow this process will delay the processing of your claim.</b>
                    </span>
                </div>
            </Container >
        </div>
    )
}

export default Claims;