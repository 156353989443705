import React, { useState } from 'react';
import { Navbar, Container, Nav, Button, Offcanvas } from 'react-bootstrap';
import nice from '../assets/nice1_logo.svg';
// import HamburgerIcon from '../assets/Vector.svg';
import { ROUTES } from '../constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';

const HeaderComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <div id='header' className='top-menu'>
      <Navbar bg="transparent" variant="dark" expand="xl">
        <Container fluid className='container-nav'>
          {/* Logo on the right */}
          <Navbar.Brand>
            <img onClick={() => navigate(ROUTES.home)} src={nice} alt="Logo" className="img-fluid" />
          </Navbar.Brand>

          {/* Offcanvas toggler for small screens */}
          <button className="navbar-toggler" type="button" onClick={handleOffcanvasToggle}>
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1.89474C0 0.846316 0.8375 0 1.875 0H21.875C22.9125 0 23.75 0.846316 23.75 1.89474C23.75 2.94316 22.9125 3.78947 21.875 3.78947H1.875C0.8375 3.78947 0 2.94316 0 1.89474ZM21.875 20.2105H1.875C0.8375 20.2105 0 21.0568 0 22.1053C0 23.1537 0.8375 24 1.875 24H21.875C22.9125 24 23.75 23.1537 23.75 22.1053C23.75 21.0568 22.9125 20.2105 21.875 20.2105ZM28.125 10.1053H8.125C7.0875 10.1053 6.25 10.9516 6.25 12C6.25 13.0484 7.0875 13.8947 8.125 13.8947H28.125C29.1625 13.8947 30 13.0484 30 12C30 10.9516 29.1625 10.1053 28.125 10.1053Z" fill="#282828" />
            </svg>
          </button>

          {/* Navbar content */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto mx-auto">
              {/* Navbar items */}
              <Nav.Link active={location.pathname === ROUTES.home} onClick={() => navigate(ROUTES.home)}>Home</Nav.Link>
              <Nav.Link active={location.pathname === ROUTES.aboutUs} onClick={() => navigate(ROUTES.aboutUs)}>About us</Nav.Link>
              {/* <Nav.Link active={location.pathname === ROUTES.testimonials} onClick={() => navigate(ROUTES.testimonials)}>Testimonials</Nav.Link> */}
              <Nav.Link active={location.pathname === ROUTES.products} onClick={() => navigate(ROUTES.products)}>Products</Nav.Link>
              <Nav.Link active={location.pathname === ROUTES.claims} onClick={() => navigate(ROUTES.claims)}>Claims</Nav.Link>
              {/* <Nav.Link active={location.pathname === ROUTES.faq} onClick={() => navigate(ROUTES.faq)}>FAQ</Nav.Link> */}
              <Nav.Link active={location.pathname === ROUTES.contactUs} onClick={() => navigate(ROUTES.contactUs)}>Contact Us</Nav.Link>
              {/* Add more Navbar items as needed */}
            </Nav>

            {/* Login and Signup buttons */}
            {/* <Nav> */}
            <div className="d-flex">
              <Button className="login-button rounded-pill mx-3 btn btn-sm btn-xs">
                Login
              </Button>

              <Button className="sign-up-button rounded-pill btn btn-sm btn-xs">Open a Nice 1 Agency</Button>
            </div>
            {/* </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Offcanvas Menu */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
        <Offcanvas.Header closeButton>
          <img onClick={() => navigate(ROUTES.home)} src={nice} alt="Logo" className="img-fluid" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link active={location.pathname === ROUTES.home} onClick={() => { navigate(ROUTES.home); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              Home
            </Nav.Link>
            <Nav.Link active={location.pathname === ROUTES.aboutUs} onClick={() => { navigate(ROUTES.aboutUs); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              About us
            </Nav.Link>
            {/* <Nav.Link active={location.pathname === ROUTES.testimonials} onClick={() => { navigate(ROUTES.testimonials); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              Testimonials
            </Nav.Link> */}
            <Nav.Link active={location.pathname === ROUTES.products} onClick={() => { navigate(ROUTES.products); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              Products
            </Nav.Link>
            <Nav.Link active={location.pathname === ROUTES.claims} onClick={() => { navigate(ROUTES.claims); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              Claims
            </Nav.Link>
            {/* <Nav.Link active={location.pathname === ROUTES.faq} onClick={() => { navigate(ROUTES.faq); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              FAQ
            </Nav.Link> */}
            <Nav.Link active={location.pathname === ROUTES.contactUs} onClick={() => { navigate(ROUTES.contactUs); setShowOffcanvas(!showOffcanvas) }}>
              <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.0641C0.000206955 13.8153 0.0937612 13.5767 0.260095 13.4009L4.7952 8.60043C4.93247 8.45517 5.04136 8.28271 5.11565 8.09289C5.18994 7.90308 5.22818 7.69963 5.22818 7.49417C5.22818 7.28872 5.18994 7.08527 5.11565 6.89546C5.04136 6.70564 4.93247 6.53318 4.7952 6.38792L0.266006 1.5906C0.104489 1.41358 0.0151158 1.1765 0.017136 0.93041C0.0191562 0.684321 0.112408 0.448917 0.276807 0.2749C0.441206 0.100882 0.663598 0.00217393 0.896084 3.54802e-05C1.12857 -0.00210297 1.35255 0.0924992 1.51978 0.263466L6.04897 5.05703C6.65797 5.70293 7 6.57819 7 7.49073C7 8.40327 6.65797 9.27854 6.04897 9.92443L1.51387 14.7249C1.39004 14.856 1.23228 14.9454 1.0605 14.9818C0.888726 15.0181 0.710625 14.9998 0.548675 14.9291C0.386725 14.8584 0.248184 14.7386 0.150535 14.5846C0.0528854 14.4307 0.000503813 14.2496 0 14.0641Z" fill="white" />
              </svg>
              Contact Us
            </Nav.Link>
          </Nav>
          {/* Login and Signup buttons */}
          <div className="d-flex justify-content-center mt-3">
            <Button variant="outline-light" className="login-button-mobile rounded-pill">Login</Button>
            <Button variant="primary" className=" sign-up-button rounded-pill mx-2">Open a Nice 1 Agency</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default HeaderComponent;
